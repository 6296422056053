.accordion-item{
    border: none !important;
}

.accordion-button:focus{
    box-shadow: none;
}
.accordion-button:not(.collapsed){
    color: #0C007F;
    border: none;
    background-color: transparent;
}

.faq-image-wrapper{
    margin-top: -70px;
}

@media screen and (max-width: 1000px) {
    .faq-gif {
        width:  50% !important;
    }
  }