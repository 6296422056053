.footer-info-text{
    font-size: 13px;
}

.footer-icon:hover{
    transform: scale(1.2);
}
    a.footer-info-link{
        color: rgb(175, 180, 184);
    }
    a.footer-info-link:hover{
        color: rgb(38, 0, 255);
    }

.footer-container{
    background-color: #2D4263;
}
.footer-container > * {
    text-align: start;
}