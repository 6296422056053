.number-wrapper{
    background: linear-gradient(to right, #2D4263, #ddd);
    border-radius: 50%;
    width: 80px;
    height: 50px;
    color:#2D4263 ;
    font-weight: 700;
    font-size: 25px;
}

.explanation{
    font-weight: 500;
    font-size: 20px;
}