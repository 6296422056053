
/* =========== Sidebar css =========== */
.sidebar-nav-wrapper {
    padding: 20px 0px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 99;
    display: block;
    grid-row: 2 / span 1;
  }

  
/* width */
.sidebar-nav-wrapper::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.sidebar-nav-wrapper::-webkit-scrollbar-track {
  background: #2D4263;
  
}

/* Handle */
.sidebar-nav-wrapper::-webkit-scrollbar-thumb {
  background: rgb(165, 164, 164);  
  border-radius: 10px;
}

/* Handle on hover */
.sidebar-nav-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}


  .sidebar-nav-wrapper .title{
    font-size: 20px;
  }
  

  .sidebar-nav-wrapper.active {
  display: block;
  }

  .sidebar-nav-wrapper .navbar-logo {
    text-align: center;
    padding: 0 25px;
    margin-bottom: 30px;
  }
  .sidebar-nav-wrapper .sidebar-nav .divider {
    padding: 5px 25px;
    width: 100%;
  }
  .sidebar-nav-wrapper .sidebar-nav .divider hr {
    height: 1px;
    background: #e2e2e2;
  }
  .sidebar-nav-wrapper .sidebar-nav ul, .toggle-sidebar-nav-wrapper .sidebar-nav ul {
    list-style: none;
  }

  .toggle-sidebar-nav-wrapper .sidebar-nav ul, .toggle-sidebar-nav-wrapper .sidebar-nav ul li a{
    color: #fff;
  }

  .sidebar-nav-wrapper .sidebar-nav ul .nav-item, .toggle-sidebar-nav-wrapper .sidebar-nav ul .nav-item {
    position: relative;
    margin: 5px 10px;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a , 
 .toggle-sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a{
    color: #fff;
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    > a::before {
    opacity: 1;
    visibility: visible;
  }

  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    > a::after {
    content: "\ea5e";
    font: normal normal normal 1em/1 "LineIcons";
    position: absolute;
    right: 25px;
    top: 16px;
    font-size: 12px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    > a.collapsed {
    color: #fff;
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    > a.collapsed::before {
    opacity: 0;
    visibility: hidden;
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    > a.collapsed::after {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul {
    padding: 0px 15px;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li {
    margin-bottom: 10px;
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    ul
    li:last-child {
    margin-bottom: 0px;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a {
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a:active {
      background-color: #dbe3f1;
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    ul
    li
    a.active,
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    ul
    li
    a:hover {
    color: #4a6cf7;
    border-color: rgba(74, 108, 247, 0.15);
    background: rgba(74, 108, 247, 0.1);
  }
  
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a i {
    font-size: 16px;
    margin-right: 15px;
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    ul
    li
    a
    span.text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .sidebar-nav-wrapper
    .sidebar-nav
    ul
    .nav-item.nav-item-has-children
    ul
    li
    a
    span.pro-badge {
    background: #4a6cf7;
    color: #fff;
    padding: 1px 6px;
    border-radius: 4px;
    font-size: 10px;
    margin-left: 10px;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item a {
    display: flex;
    align-items: center;
    font-weight: 500;
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;
    background: rgba(75, 56, 250, 0.678);
    border-radius: 0 3px 3px 0px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item a span.text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item a span.pro-badge {
    background: #4a6cf7;
    color: #fff;
    padding: 8px 6px;
    border-radius: 4px;
    font-size: 10px;
    margin-left: 10px;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item a .icon {
    margin-right: 12px;
    font-size: 18px;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item a .icon svg {
    fill: currentColor;
  }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a,
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a.collapsed,
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a,
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed {
    color: #212530;
    background-color: rgba(255, 255, 255, 0.705);
  }
  

  .sidebar-nav-wrapper .sidebar-nav ul .nav-item:active > a::before,
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item:active > a.collapsed::before,
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a::before,
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed::before  {
    opacity: 1;
    visibility: visible;
  }

  .activePage{
    background-color:   rgba(255, 255, 255, 0.705);
    border-radius: 4px;
    
    color:#212530!important;
  }

  .activePage .text{
    font-weight: 700;
  }

  .logout-wrapper{
    margin-bottom: 100px;
  }


.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a {
  color: #fff;
}
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  > a::before {
  opacity: 1;
  visibility: visible;
}

.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  > a::after {
  content: "\ea5e";
  font: normal normal normal 1em/1 "LineIcons";
  position: absolute;
  right: 25px;
  top: 16px;
  font-size: 12px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  > a.collapsed {
  color: #fff;
}
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  > a.collapsed::before {
  opacity: 0;
  visibility: hidden;
}
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  > a.collapsed::after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul {
  padding: 0px 15px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li {
  margin-bottom: 10px;
}
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  ul
  li:last-child {
  margin-bottom: 0px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a {
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  ul
  li
  a.active,
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  ul
  li
  a:hover {
  color: #4a6cf7;
  border-color: rgba(74, 108, 247, 0.15);
  background: rgba(74, 108, 247, 0.1);
}

.sidebar-nav-wrapper .active-class{
    background-color: #dbe3f1;
    border-radius: 6px;
    border-color: rgba(74, 108, 247, 0.15);
    opacity: 1;
    visibility: visible;
  }

  .active-class::before {
    opacity: 1 !important;
    visibility: visible !important;
}

.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a i {
  font-size: 16px;
  margin-right: 15px;
}
.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  ul
  li
  a
  span.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}



.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a, .toggle-sidebar-nav-wrapper .sidebar-nav ul .nav-item > a span{
    color: #fff;
  }



.sidebar-nav-wrapper
  .sidebar-nav
  ul
  .nav-item.nav-item-has-children
  ul
  li
  a
  span.pro-badge {
  background: #4a6cf7;
  color: #fff;
  padding: 1px 6px;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 10px;
}

@media (max-width: 576px) { /* sm breakpoint */
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item  {
      margin: 5px 0px;
  }
  .header-size-sm {
      font-size: 15px; 
  }
}


.toggle-sidebar-nav-wrapper {
  background:#2D4263;
  width: 250px;
  padding: 20px 0px;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  right: 0;
  z-index: 99;
  display: none;
}

.toggle-sidebar-nav-wrapper.active {
  display: block;
  }



  @media screen and (min-width: 768px) {
    
    .toggle-sidebar-nav-wrapper.active{
       display: none;
    }
  }