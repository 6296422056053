.first-section h1{
    font-size: 44px;
    font-weight: 700;
    line-height: 1.2em;
    
}

.first-section{
    z-index: 1;  
}

.title-style{
    color: #292849;
}

.world-gif{
    width: 125%;
}


@media screen and (max-width: 2400px) {
    .first-section_desc {
        font-size: 150%; 
    }
    .first-section{
        height: 100%;
    }
    .world-gif{
        width: 145%;
    }
    
  }
@media screen and (max-width: 2006px) {
    .first-section_desc {
        font-size: 120%; 
    }
    .first-section{
        height: 70%;
    }
    .world-gif{
        width: 135%;
    }
    
  }

  @media screen and (max-width: 1400px) {
    .first-section_desc {
        font-size: 16px; 
    }
    .first-section{
        height: 100vh;
    }
   
  }
  @media screen and (max-width: 1000px) {
    .world-gif{
        display: none;
    }
    .security-gif{
        display: none;
    }
  }
