.header-dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
 }
.header-dropdown .dropdown-menu {
    box-shadow: 0px 0px 26px 0px rgb(55 42 255 / 14%);
    border: none;
    border-radius: 10px;
 }

 .header-dropdown .dropdown-item:focus, 
 .header-dropdown .dropdown-item:hover{
   background-color: transparent;
 }

 .header-dropdown .dropdown-menu li{
   height: 60px;
   padding: 0px 40px;
 }


 .header-dropdown .dropdown-menu li:hover{
    background-color: #F7F7FB !important;
 }

 .header-dropdown{
    position: relative !important;
 }

 .header-dropdown .btn:hover,
 .header-dropdown .btn:active,
 .header-dropdown .btn:focus,
 .header-dropdown .btn {
    border: none;
 }

 .header-dropdown .dropdown-toggle{
    font-weight: 500;
 }

 .header-dropdown .dropdown-toggle::after{
    display: none;
 }
 .header-nav {
    height: 70px;
/*     position: sticky;
    top: 0; */
    position: relative;
    box-shadow: 0 0 13px 0 rgb(55 42 255 / 10%);
    z-index: 100; 
    background-color: #fff;
    grid-column: 1 / span 2;
 }

 .sticky-nav {
   position: fixed;
   top: 0;
   left: 0;
 }

 .logo-img{
   max-width: 70px;
 }

 .title{
   color: black;
 }
.header-content{
   display: flex;
}

 @media screen and (max-width: 768px) {
   .header-nav a,
   .header-dropdown button{
       font-size: 12px;
   }

   .header-content{
      display: none;
   }
 }



 