.custom-button{
    width: max-content;
    height: 40px;
    background-color: #0C007F;
    border: 1px solid #0C007F;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
   /*  z-index: 100; */
    font-size: 16px;
}



@media screen and (max-width: 768px) {
    .custom-button{
        font-size: 14px;
        padding: 0px 5px;
    }
  }