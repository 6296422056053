.second-section{
    background-color: #2D4263;
    color: #fff;
}

.second-section .paragraph {
    font-size: 19px;
}

.second-section .image{
    border-radius: 15px;
    
}