.third-section .icon-container{
    background-color: rgba(123, 193, 233, 0.863);
    border-radius: 200px;
    height: 50px;
    width: 50px;
    color: #fff;
}

.third-section-icon{
    height: 60px;
    width: 60px;
}

.third-section-header{
    color: rgb(21, 21, 75);
}