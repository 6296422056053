.second-section_card,
.blog-card{
    box-shadow: 0px 0px 20px 0px rgb(55 42 255 / 16%);
    border-radius: 10px;
}

.blog-card .card-description{
    padding: 30px 30px;
}

.second-section_card .card-description{
    font-size: 13px;
    padding: 30px 30px;
}
.card-nav:hover >
.card-nav-icon{
    transform: scale(1.5);
}

@media screen and (max-width: 2400px) {
    .second-section_card {
        width: 23%;  
    }
    .second-section_card:not(:last-child){
        margin-right: 1.9rem;
    }
  }
@media screen and (max-width: 2006px) {
    .second-section_card {
        width: 23%;  
    }
    .second-section_card:not(:last-child){
        margin-right: 1.9rem;
    }
  }
@media screen and (max-width: 1400px) {
    .second-section_card {
        width: 30%;
        
    }
    .second-section_card:not(:last-child){
        margin-right: 1.9rem;
    }
  }
@media screen and (max-width: 1200px) {
    .second-section_card {
        width: 24%;
        
    }
    .second-section_card:not(:last-child){
        margin-right: 0.8rem;
    }
  }

@media screen and (max-width: 992px) {
    .second-section_card {
        width: 45%;
    } 
    
  }
@media screen and (max-width: 768px) {
    .second-section_card {
        width: 95%
    } 
    
  }