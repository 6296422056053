.text-key{
    font-weight: 600;
}

@media screen and (max-width: 1000px) {
    .gif {
        display: none;
    }
    .content-wrapper{
        width: 100% !important;
    }
  }