body{
    background-color: #fff;
}

.main-page{
    height: 100vh;
}

.main-page::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10%;
    left: -40%;
    z-index: -1;
    background: url(../assets/svg/curve-line.svg) 0 0 no-repeat;
    
  }
.blue-text{
    color: #0C007F;
}
