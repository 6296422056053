.app-layout{
    grid-template-columns: 13rem 1fr;
    grid-template-rows: 4rem 1fr;
    height: 100vh;
    overflow-x: hidden;
    display: grid;
    width: 100vw;
}

.sidebar{
    background-color: var(--primary-background-color)   ;
    padding: 3.2rem 1rem;
    grid-row: 2 / span 1; /* Make the sidebar start from the second row and span one row */
    display: flex;
    border: var(--primary-border);
    box-shadow: 5px 0px 10px  rgba(200, 208, 216, 0.3);
    flex-direction: column;
    color: var(--primary-text-color);
}

.blue-heading{
    color: rgb(12, 0, 127);
}


img {
    max-width: 100%;
    height: auto;
}



@media screen and (max-width: 768px) {
    .app-layout {
        grid-template-columns: 8rem 1fr;
        grid-template-rows: auto auto;
    }
}

@media (max-width: 576px) { /* sm breakpoint */
    .text-size-sm {
        font-size: 13px; 
    }
    .header-size-sm {
        font-size: 15px; 
    }
}
@media (min-width: 576px) { /* sm breakpoint */
    .text-size-sm {
        font-size: 15px; 
    }
    .header-size-sm {
        font-size: 20px; 
    }
}
@media (min-width: 768px) { /* md breakpoint */
    .text-size-md {
        font-size: 18px; 
    }
    .header-size-md {
        font-size: 27px; 
    }
}

