.main-btn {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0px 0px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 5;
    transition: all 0.4s ease-in-out;
    border: 1px solid transparent;
    overflow: hidden;
    background-color: transparent;
  }
  .main-btn:hover {
    color: inherit;
  }
.menu-toggle-btn{
    display: none;
}
  @media screen and (max-width: 768px) {
        .menu-toggle-btn{
            display: inline-block;
        }
  }