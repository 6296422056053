.blog-container{
    
    background-color: #f2f2f2;
}

.price-tag p:hover{
  text-decoration: underline;
}

.price-tag {
    display: inline-block;
    cursor: pointer;
    width: auto;
    height: 36px;
    
    background-color: #0C007F;
    -webkit-border-radius: 3px 4px 4px 3px;
    -moz-border-radius: 3px 4px 4px 3px;
    border-radius: 3px 4px 4px 3px;
    
    border-left: 1px solid #0C007F;
  
    /* This makes room for the triangle */
    margin-left: 18px;
    
    position: relative;
    
    color: white;
    line-height: 38px;
  
    padding: 0 10px 0 10px;
  }
  
  /* Makes the triangle */
  .price-tag:before {
    content: "";
    position: absolute;
    display: block;
    left: -18px;
     height: auto;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 19px solid #0C007F;
  }
  
  /* Makes the circle */
  .price-tag:after {
    content: "";
    background-color: white;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: block;
    position: absolute;
    left: -9px;
    top: 17px;
  }

  .paragraph{
    font-size: 20px;
  }