.form-wrapper{
    box-shadow: 0px 0px 35px 0px rgb(20 17 91 / 11%);
    border-radius: 20px;
}

.empty-field{
    border: 1px solid red;
}



.message{
    background: rgba(33, 150, 83, 0.1);
    color: #219653;
    border-radius: 5px;
}
